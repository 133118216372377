.Form-Calendar-Header-Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  flex-wrap: wrap;
}

.line-break{
  width: 100%;
}

.Form-Calendar-Header {
  padding: 5px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  text-align: center;
}

.Form-Calendar-Container {
  white-space: pre-wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Form-Calendar-Date-Container {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

.Form-Calendar-Time-Container {
  padding: 10px 0px;
  display: flex;
  justify-content: space-evenly;
}

.Form-Calendar-Time, .Form-Calendar-Time-Selected {
  padding: 4px 8px;
  color: rgb(0, 0, 0);
  border-radius: 5px;
}

.Form-Calendar-Time-Selected{
  color: #fff;
}

.Form-Calendar-Time {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.025);
}

.Form-Calendar-Time:hover {
  cursor:pointer;
  color: #fff;
  background-color: #c0c0c0;
}

.Form-Calendar-Time-Selected {
  background-color:#E30011;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.Form-Calendar-Date, .Form-Calendar-Date-Selected {
  padding: 4px 8px;
  color:rgb(0, 0, 0);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
}

.Form-Calendar-Date {
  width: 42px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.025);
}

.Form-Calendar-Date:hover {
  background-color: #c0c0c0;
  cursor: pointer;
  color: #fff;
}

.Form-Calendar-Date-Selected {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  width: 80px;
  white-space: nowrap;
  background-color:#E30011;
  border-radius: 5px;
  color: #fff;
}

.Form-Calendar-Date-From, .Form-Calendar-Date-To {
  display: flex;
  justify-content: center;
}

.Form-Calendar-Month {
  width: 140px;
  text-align: center;
  font-size: 17px;
  align-self: center;
  color: rgb(0, 0, 0);
}

.Form-Calendar {
  border-radius: 10px;
  background: rgb(253, 253, 253);
  margin-bottom: 10px;
  border: 1px solid rgb(167, 167, 167);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Form-Calendar-Month-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 0px;
}

.Form-Calendar-Arrow-Left:hover, .Form-Calendar-Arrow-Right:hover, .Form-Calendar-Date-Arrow:hover {
  background-color: rgb(0, 0, 0);
  cursor: pointer;
}

.Form-Calendar-Date-Arrow {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.arrow-container {
  width: 100%;
  position: relative;
}

.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 6px;
}

.doublearrow-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.doublearrow {
  border: solid rgb(255, 255, 255);
  border-width: 0px 2px 2px 0;
  position: absolute;
  padding: 6px;
}

.doublearrow::after {
  margin: 0;
  content: '';
  border: solid rgb(255, 255, 255);
  border-width: 0px 2px 2px 0;
  position: absolute;
  padding: 6px;
}

.right {
  transform: rotate(-45deg) translate(-3px, -2px);
  -webkit-transform: rotate(-45deg) translate(-3px, -2px);
}

.doubleright {
  transform: rotate(-45deg) translate(-5px, -3px);
  -webkit-transform: rotate(-45deg) translate(-5px, -3px);
}

.left {
  transform: rotate(135deg) translate(-2px, -3px);
  -webkit-transform: rotate(135deg) translate(-2px, -3px);
}

.doubleleft {
  transform: rotate(135deg) translate(-4px, -6px);
  -webkit-transform: rotate(135deg) translate(-4px, -6px);
}