.Animated-Div {
  transform-origin: top;
  transition: all 0.5s ease;
}

.LinkButton {
  font-size: 0.875rem;
  text-decoration: none;
  padding: 0.675rem 1rem;
  background-color: #fff;
  border: 1px solid #000;
  outline: none;
  color: #000;
  transition: all 0.3s ease;
}

.LinkButton a:-webkit-any-link {
  text-decoration: none;
}

.LinkButton > a {
  margin-bottom: 25px;
}

.LinkButton:hover {
  color: #fff;
  background-color: #000;
  cursor: pointer;
}

.Form-Success-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form-Success {
  border: 1px solid #000;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Form-Error-Container p{
  font-size: 20px;
  margin: 0px;
  color: rgb(255, 65, 65);
}

.Error {
  opacity: 0.9;
}

.Error p {
  background-color: rgb(255, 158, 158);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color:#000;
  position: absolute;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: -33px;
  margin-left: 10px;
}

.Error p:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 15px;
	border: 17px solid transparent;
	border-top-color: rgb(255, 158, 158);
	border-bottom: 0;
	border-right: 0;
	margin-left: -8px;
	margin-bottom: -16px;
}

.Form-Container {
  height: 100%;
}

.Header-Container {
  height: 20rem;
  background-image: url("../Assets/storage_logo2.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header-Container h1 {
  text-decoration: underline;
  backdrop-filter: blur(2px); 
  -webkit-backdrop-filter: blur(2px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.527);
  z-index: 2;
  font-size: 1.875rem;
  text-align: center;
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 1px #5c5c5c;
  background-color: rgba(0, 0, 0, 0.288);
  border-radius: 50px;
  position: relative;
  padding: 5px 25px;
}

.Form {
  max-width: 1200px;
  margin: 0 auto;
}

.Form h1 {
  font-size: 1.875rem;
  color: #444;
  padding: 10px 0px;
}

.Form h2 {
  text-decoration: underline #ddd;
  font-size: 22px;
  color: #333;
  padding-bottom: 0px;
}

.Form h4 {
  font-size: 20px;
  color: #222;
  padding: 10px 0px;
}

.Form-Container .Form {
  width: 100%;
  padding: 15px 100px;
}

.Form-Element-Radio, .Form-Element-CheckBox, .Form-Element-Select {
  color: #333;
  display: flex;
  flex-flow: row;
  padding-bottom: 10px; 
}

.Form-Element-Radio label, .Form-Element-CheckBox label, .Form-Element-Select label {
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  flex: 30%;
  color: #333;
  align-self: center;
}

.Form-Element-CheckBox div{
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  flex: 70%;
  justify-content: flex-start;
  align-self:flex-start;
}

.Form-Element-Select div{
  height: 100% !important;
  flex: 70%;
  justify-content: flex-start;
  align-self:flex-start;
}

.Form-Element-Radio div:nth-child(2), .Form-Element-Radio div:nth-child(3) {
  flex: 35%;
  font-size: 17px;
  padding: 10px 0px;
}

.Form-Element-Radio div input {
  margin-top:0px;
  margin-right: 10px;
}

.Form-Element-Radio div label {
  margin-right: 10px;
}

.Form-Element {
  color: #444;
  display: flex;
  flex-flow: row;
  padding-bottom: 10px;
}

.Form-Element label {
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  flex: 30%;
  color: #333;
  align-self: center;
  flex-direction: row;
}

.InfoSquare {
  position: absolute;
  border-radius: 3px;
  justify-content: center;
  align-self: center;
  background: #000;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  display: inline-flex;
} 

.InfoSquare-Text {
  width: 480px;
  max-width: 300px;
  position: absolute;
  border-radius: 13px;
  justify-content: center;
  align-self: center;
  background: #000;
  color: #fff;
  display: block;
  bottom: 16px;
  left: -8px;
  padding: 8px;
}

.InfoSquare-Text:after {
  content: "";
  position: absolute;
  bottom: -13px; /* value = - border-top-width - border-bottom-width */
  border-width: 15px 15px 0; /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #000000 transparent;
  display: block;
  width: 0;
}

.InfoSquare-TextCont {
  display: inline-flex;
  position: absolute;
}

.Form-Element input{
  border: 1px solid #bbb;
  border-radius: 10px;
  flex: 70%;
  font-size: 17px;
  padding: 8px 10px;
  height: 100%;
}

.Form-Element-Select div select{
  height: 100% !important;
  width:100% !important;
  padding: 10px 10px;
  font-size: 17px;
  border: 1px solid #ccc;
}

.Form-Element-Select div select:focus{
  outline: 0px;
  border: 1px solid #888;
}

.Form-Element input:focus {
  outline: 0px;
  border: 1px solid #888;
}

@media only screen and (max-width: 900px) {
  .Form-Container .Form {
    width: 100%;
    padding: 10px 60px;
  }
  
  .Form h1 {
    font-size: 1.4375rem;
  }
}

.Button {
  height: 40px !important;
  padding: 0px !important;
}

.Button input{
  height: 40px;
  width: 250px;
  border: 0px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
}

/********** MOBILE *************/
@media only screen and (max-width: 700px) {
  .Form-Container .Form {
    padding: 5px 25px !important;
  }
  
  .Form-Element, .Form-Element-Radio, .Form-Element-CheckBox {
    flex-flow: column;
  }
  
  .Form-Element label, .Form-Element-Radio label, .Form-Element-CheckBox label {
    flex: 1;
    padding-top: 0px;
    align-self: flex-start;
  }
  
  .Form-Element div, .Form-Element-Radio div, .Form-Element-CheckBox div {
    padding-top: 0px !important;
  }
  
  .Form-Element input {
    flex: 1;
  }

  .Button {
    align-self: center;
  }

  .Form h2 {
    text-align: center;
    padding: 0px;
    margin-top: 5px;
  }
}

